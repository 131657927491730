<template>
  <Main>
    <div slot="left">
      <p class="title">搜索流量7天破千</p>
      <p class="desc">
        用户量突破10万
      </p>
    </div>
    <div slot="right">
      <p class="r-title">欢迎登录</p>
      <div class="pdt20">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input
              class="input"
              prefix-icon="el-icon-mobile-phone"
              v-model="form.username"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="input"
              prefix-icon="el-icon-unlock"
              v-model="form.password"
              placeholder="请输入密码"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="pdt120">
        <el-button size="medium" class="login" type="primary" @click="submit"
          >登录</el-button
        >
        
      </div>
    </div>
  </Main>
</template>
<script>
import Main from "@/components/front/main";
export default {
  components: {
    Main,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      verifyUrl: "",
      url:{
        index:"/sysaccount/signin",
      }
    };
  },
  created() {
    this.enterKey();
  },
  methods: {
    goPage: function (name) {
      this.$router.push({ name: name });
    },
    enterKey: function () {
      document.onkeydown = () => {
        var key = window.event.keyCode;
        if (key == 13) {
          this.submit();
        }
      };
    },
    submit: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$rq
            .postAction(this.url.index, this.form)
            .then((rs) => {
              if (rs.code == 1) {
                //登录成功
                this.$func.save("user-info", rs.info);
                this.$router.push({ name: "BackendMemberIndex" });
              } else {
                this.$message({
                  message: rs.err,
                  type: "warning",
                });
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
}
.r-title {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #222;
}
.desc {
  font-size: 16px;
  margin-top: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  color: #b2b2b2;
}
.login {
  width: 100%;
  padding: 16px 0px;
}
.input .el-input__inner {
  font-size: 16px;
}
.code {
  position: absolute;
  top: 0px;
  height: 40px;
  right: 0px;
  z-index: 10;
  border-radius: 4px 4px 0px 0px;
}
</style>